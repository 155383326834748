import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../service/Feedback';
import Connection from '../../service/Connection';
import { upsertOneDocumentPreinscription } from '../../store/slices/entities/documents_preinscriptions';
import { upsertOnePostulation } from '../../store/slices/entities/postulations';

const emptyState = {
    GetDocuments: {
        status: 'idle',
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
};

export const operationsSlice = createSlice({
    name: 'operations',
    initialState: emptyState,
    reducers: {},
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        builder.addCase(GetDocument.rejected, (state, action) => {
            state.GetDocuments.status = 'rejected';
            state.GetDocuments.feedback = action.payload.feedback;
        });

        builder.addCase(GetDocument.pending, (state, action) => {
            state.GetDocuments.status = 'pending';
        });
        builder.addCase(GetDocument.fulfilled, (state, action) => {
            state.GetDocuments.status = 'fulfilled';
        });
    },
});

export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const GetDocument = createAsyncThunk(
    'certificates/Document/Get',
    async ({ data, documentId, uuid }, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
