import React from 'react';
import WorkingMiddleware from './WorkingMiddleware';
import ThemeProviderWrapper from '../theme/reverseTokens';
import Layout from './Layout/Layout';
import PrivateRoute from './PrivateRoute';
import Configuraciones from '../views/Configuraciones/Configuraciones';
import Usuarios from '../views/Usuarios/Usuarios';
import Alumnos from '../views/Alumnos/Alumnos';
import AlumnosGroup from '../views/AlumnosGroup/AlumnosGroup';
import Groups from '../views/Groups/Groups';
import ReportsHome from '../moduleReports/pages/Home';
import ReportsGroupsDetails from '../moduleReports/pages/GroupsDetails';
import ReportsStudentsDetails from '../moduleReports/pages/StudentsDetails';
import NoticesHome from '../Notices/pages/Home/NoticesHome';
import Avisos from '../views/Avisos/Avisos';
import ParentHome from '../views/ParentHome';
import ScoreStudents from './../views/Scores/pages/Students';
import ScoresGroups from './../views/Scores/pages/Groups';
import Scores from './../views/Scores';
import Materias from '../views/Materias/Materias';
import Perfil from '../views/Perfil';
import Calendar from './../views/Calendario';
import MakeReport from '../moduleProfessors/pages/MakeReport';
import AssistanceList from '../moduleProfessors/pages/AssistanceList';
import ScoreEdit from '../moduleProfessors/pages/ScoreEdit';
import JustifyAssistsPage from '../moduleProfessors/pages/JustifyAssistsPage';
import HomeProfessors from '../Professors/pages/Home/HomeProfessors';
import Home from '../moduleProfessors/pages/Home';
import ClassroomSubjectAssistance from '../Professors/pages/ClassroomSubjects/AssistanceList/ClassroomSubjectAssistance';
import ClassroomSubjectScores from '../Professors/pages/ClassroomSubjects/Scores/ClassroomSubjectScores';
import ClassroomSubjectsJustify from '../Professors/pages/ClassroomSubjects/JustifyAssists/ClassroomSubjectsJustify';
import ClassroomGroupAssistance from '../Professors/pages/ClassroomGroups/AssitanceList/ClassroomGroupAssistance';
import ClassroomGroupScores from '../Professors/pages/ClassroomGroups/Scores/ClassroomGroupScores';
import ClassroomGroupJustify from '../Professors/pages/ClassroomGroups/JustifyAssists/ClassroomGroupJustify';
import Testing from '../views/Testing';
import Detalles from '../views/Detalles';
import Supervisiones from '../views/Supervisiones';
import DashboardPayments from '../PaymentModule/pages/Dashboard';
import Agreements from '../PaymentModule/pages/Agreements';
import Concepts from '../PaymentModule/pages/Concepts';
import Discounts from '../PaymentModule/pages/discounts';
import Income from '../PaymentModule/pages/Income';
import Surcharges from '../PaymentModule/pages/surcharges';
import DirectorNewHome from '../moduleDirector/pages/Home';
import DirectorGroups from '../moduleDirector/pages/Groups';
import DirectorSubjects from '../moduleDirector/pages/Subjects';
import DirectorProfessors from '../moduleDirector/pages/Professors';
import DirectorStudents from '../moduleDirector/pages/Students';
import Regularizations from '../Regularizations/pages/Regularizations';
import FeatureFlags from '../../src/service/FeatureFlags';
import PreInscripciones from '../../src/modulePreInscriptions/pages/PreInscriptions';
import Certificates from '../../src/moduleCertificates/pages/index';

const InnerRoutes = () => {
    const featureFlagPaymentsModule = FeatureFlags.isFeatureFlagActive(
        'NEW_PAYMENTS_MODULE'
    );
    const featureFlagProfessors = FeatureFlags.isFeatureFlagActive(
        'NEW_PROFESSOR_MODULE'
    );

    const featureFlagNotices =
        FeatureFlags.isFeatureFlagActive('NEW_NOTICES_MODULE');

    const featureFlagRegularizationsModule = FeatureFlags.isFeatureFlagActive(
        'NEW_REGULARIZATIONS_MODULE'
    );

    const featureFlagPreInscriptionsModule = FeatureFlags.isFeatureFlagActive(
        'NEW_PRE_INSCRIPTIONS_MODULE'
    );
    const featureFlagCertificatesModule = FeatureFlags.isFeatureFlagActive(
        'NEW_CERTIFICATES_MODULE'
    );

    return (
        <WorkingMiddleware>
            <ThemeProviderWrapper>
                <Layout>
                    <PrivateRoute path="/configuracion">
                        <Configuraciones></Configuraciones>
                    </PrivateRoute>
                    <PrivateRoute path="/usuarios">
                        <Usuarios></Usuarios>
                    </PrivateRoute>

                    <PrivateRoute path="/alumnos">
                        <Alumnos></Alumnos>
                    </PrivateRoute>

                    <PrivateRoute exact path="/alumnos-grupo/:id">
                        <AlumnosGroup></AlumnosGroup>
                    </PrivateRoute>

                    <PrivateRoute path="/grupos">
                        <Groups></Groups>
                    </PrivateRoute>

                    <PrivateRoute exact path="/reportes">
                        <ReportsHome></ReportsHome>
                    </PrivateRoute>
                    <PrivateRoute exact path="/reportes/:id">
                        <ReportsGroupsDetails></ReportsGroupsDetails>
                    </PrivateRoute>
                    <PrivateRoute exact path="/reportes/:groupId/alumno/:id">
                        <ReportsStudentsDetails></ReportsStudentsDetails>
                    </PrivateRoute>
                    <PrivateRoute path="/avisos">
                        {featureFlagNotices ? <NoticesHome /> : <Avisos />}
                    </PrivateRoute>
                    <PrivateRoute path="/padres">
                        <ParentHome />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/calificaciones/:groupId/alumno/:id">
                        <ScoreStudents></ScoreStudents>
                    </PrivateRoute>
                    <PrivateRoute exact path="/calificaciones/:id">
                        <ScoresGroups></ScoresGroups>
                    </PrivateRoute>
                    <PrivateRoute exact path="/calificaciones">
                        <Scores></Scores>
                    </PrivateRoute>
                    <PrivateRoute path="/materias">
                        <Materias></Materias>
                    </PrivateRoute>

                    <PrivateRoute path="/perfil">
                        <Perfil></Perfil>
                    </PrivateRoute>
                    <PrivateRoute path="/calendario">
                        <Calendar></Calendar>
                    </PrivateRoute>
                    <PrivateRoute path="/inicio-maestros/reportar/:id/alumno/:studentId">
                        <MakeReport />
                    </PrivateRoute>
                    <PrivateRoute path="/inicio-maestros/lista-asistencia/:id">
                        <AssistanceList />
                    </PrivateRoute>
                    <PrivateRoute path="/inicio-maestros/calificaciones/:id">
                        <ScoreEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/inicio-maestros/justify-assists-page/:id">
                        <JustifyAssistsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/inicio-maestros">
                        {featureFlagProfessors ? <HomeProfessors /> : <Home />}
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/inicio-maestros/classroom-subject/:id">
                        <ClassroomSubjectAssistance />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/inicio-maestros/classroom-subject/:id/scores/:subjectId">
                        <ClassroomSubjectScores />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/inicio-maestros/classroom-subject/:id/justify/:subjectId">
                        <ClassroomSubjectsJustify />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/inicio-maestros/classroom-group/:id">
                        <ClassroomGroupAssistance />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/inicio-maestros/classroom-group/:id/scores/:subjectId">
                        <ClassroomGroupScores />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/inicio-maestros/classroom-group/:id/justify">
                        <ClassroomGroupJustify />
                    </PrivateRoute>
                    <PrivateRoute path="/testing">
                        <Testing />
                    </PrivateRoute>
                    <PrivateRoute exact path="/Supervisiones/Detalles/:id">
                        <Detalles />
                    </PrivateRoute>
                    <PrivateRoute exact path="/Supervisiones">
                        <Supervisiones />
                    </PrivateRoute>
                    {featureFlagPaymentsModule && (
                        <>
                            {' '}
                            <PrivateRoute exact path="/Finanzas">
                                <DashboardPayments />
                            </PrivateRoute>
                            <PrivateRoute exact path="/Finanzas/inicio">
                                <DashboardPayments />
                            </PrivateRoute>
                            <PrivateRoute exact path="/Finanzas/convenios">
                                <Agreements />
                            </PrivateRoute>
                            <PrivateRoute exact path="/Finanzas/conceptos">
                                <Concepts />
                            </PrivateRoute>
                            <PrivateRoute exact path="/Finanzas/descuentos">
                                <Discounts />
                            </PrivateRoute>
                            <PrivateRoute exact path="/Finanzas/pagos">
                                <Income />
                            </PrivateRoute>
                            <PrivateRoute exact path="/Finanzas/recargos">
                                <Surcharges />
                            </PrivateRoute>
                        </>
                    )}
                    <>
                        <PrivateRoute exact path="/NewDirector/inicio">
                            <DirectorNewHome />
                        </PrivateRoute>
                        <PrivateRoute exact path="/NewDirector/groups">
                            <DirectorGroups />
                        </PrivateRoute>
                        <PrivateRoute exact path="/NewDirector/subjects">
                            <DirectorSubjects />
                        </PrivateRoute>
                        <PrivateRoute exact path="/NewDirector/professors">
                            <DirectorProfessors />
                        </PrivateRoute>
                        <PrivateRoute exact path="/NewDirector/students">
                            <DirectorStudents />
                        </PrivateRoute>

                        {featureFlagRegularizationsModule && (
                            <PrivateRoute exact path="/regularizaciones">
                                <Regularizations />
                            </PrivateRoute>
                        )}

                        {featureFlagPreInscriptionsModule && (
                            <PrivateRoute exact path="/pre-inscripciones">
                                <PreInscripciones />
                            </PrivateRoute>
                        )}
                        {featureFlagCertificatesModule && (
                            <PrivateRoute exact path="/Certificaciones">
                                <Certificates />
                            </PrivateRoute>
                        )}
                    </>
                </Layout>
            </ThemeProviderWrapper>
        </WorkingMiddleware>
    );
};

export default InnerRoutes;
