import React, { useState } from 'react';
import {
    Box,
    Grid,
    Divider,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';
import Header from '../../../components/utilities/Header';
import { Card } from 'antd';

export const GroupsCards = () => {
    const cardStyles = {
        height: 240,
        backgroundColor: (index, colors) => colors[index % colors.length],
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '18px',
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
            transform: 'scale(1.03)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        },
    };

    const colors = [
        '#00C483',
        '#ffbf43',
        '#63A1DF',
        '#ff6160',
        '#FF4A8B',
        '#63A1DF',
        '#00C483',
        '#ffbf43',
    ];

    const data = [
        { grupo_id: 1, nivel: 'Primaria', grade: 3, grupo: 'A', ciclo: 2024 },
        { grupo_id: 2, nivel: 'Primaria', grade: 3, grupo: 'B', ciclo: 2024 },
        { grupo_id: 3, nivel: 'Primaria', grade: 3, grupo: 'C', ciclo: 2024 },
        { grupo_id: 4, nivel: 'Primaria', grade: 3, grupo: 'D', ciclo: 2024 },
        { grupo_id: 5, nivel: 'Primaria', grade: 3, grupo: 'E', ciclo: 2024 },
        { grupo_id: 6, nivel: 'Primaria', grade: 3, grupo: 'F', ciclo: 2024 },
        { grupo_id: 7, nivel: 'Primaria', grade: 3, grupo: 'G', ciclo: 2024 },
        { grupo_id: 8, nivel: 'Primaria', grade: 3, grupo: 'H', ciclo: 2024 },
        { grupo_id: 9, nivel: 'Secundaria', grade: 1, grupo: 'A', ciclo: 2023 },
        {grupo_id: 10,nivel: 'Secundaria',grade: 2,grupo: 'B',ciclo: 2023},
        {grupo_id: 11,nivel: 'Secundaria',grade: 3,grupo: 'C',ciclo: 2022 },
    ];

    const availableCycles = [...new Set(data.map((item) => item.ciclo))].sort(
        (a, b) => b - a
    );
    const [selectedCycle, setSelectedCycle] = useState(availableCycles[0]);

    const filteredData = data.filter((item) => item.ciclo === selectedCycle);

    const handleCardClick = (grupo) => {
        
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}>
                <Header
                    title="Boletas y Certificaciones"
                    subtitle="Administra las boletas y certificaciones de tu escuela."
                />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{ color: 'grey.600', mr: 2, fontWeight: 'bold' }}>
                        Ciclo escolar:
                    </Typography>
                    <Select
                        value={selectedCycle}
                        onChange={(e) => setSelectedCycle(e.target.value)}
                        sx={{ minWidth: 190 }}>
                        {availableCycles.map((cycle) => (
                            <MenuItem key={cycle} value={cycle}>
                                {cycle}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Grid container spacing={3}>
                {filteredData.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.grupo_id}>
                        <Card
                            hoverable
                            style={{
                                ...cardStyles,
                                backgroundColor: colors[index % colors.length],
                            }}
                            onClick={() => handleCardClick(item)}>
                            <Typography variant="h5" className='text-white' fontWeight="bold">
                                {item.nivel}
                            </Typography>
                            <Typography variant="h3" className='text-white' fontWeight="bold" my={1}>
                                {item.grade}° {item.grupo}
                            </Typography>
                            <Typography variant="body1">
                                Ciclo {item.ciclo}
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};