import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const documentsCertificatesAdapter = createEntityAdapter({
    selectId: (documentsCertificates) => documentsCertificates.id,
    sortComparer: (a, b) => a.id - b.id,
});

/**
 * Slice para las boletas
 */
export const documentsCertificatesSlice = createSlice({
    name: 'documentsCertificates ',
    initialState: documentsCertificatesAdapter.getInitialState(),
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return documentsCertificatesAdapter.getInitialState();
        });
    },
    reducers: {
        setAllDocumentsCertificates: documentsCertificatesAdapter.setAll,
        addManyDocumentsCertificates: documentsCertificatesAdapter.addMany,
        addOneDocumentsCertificates: documentsCertificatesAdapter.addOne,
        removeOneDocumentsCertificates: documentsCertificatesAdapter.removeOne,
        upsertOneDocumentsCertificates: documentsCertificatesAdapter.upsertOne,
        upsertManyDocumentsCertificates:
            documentsCertificatesAdapter.upsertMany,
        updateManyDocumentsCertificates:
            documentsCertificatesAdapter.updateMany,
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    addManyDocumentsCertificates,
    removeOneDocumentsCertificates,
    upsertOneDocumentsCertificates,
    addOneDocumentsCertificates,
    upsertManyDocumentsCertificates,
    setAllDocumentsCertificates,
    updateManyDocumentsCertificates,
} = documentsCertificatesSlice.actions;

const globalizedSelectors = documentsCertificatesAdapter.getSelectors(
    (state) => state.entities.documentsCertificates
);

export const selectDocumentsPreinscriptions = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllDocumentsCertificates = (state) =>
    globalizedSelectors.selectAll(state);

export default documentsCertificatesSlice.reducer;
