import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const documentsGradesAdapter = createEntityAdapter({
    selectId: (documentsGrades) => documentsGrades.id,
    sortComparer: (a, b) => a.id - b.id,
});

/**
 * Slice para las boletas
 */
export const documentsGradesSlice = createSlice({
    name: 'documentsGrades',
    initialState: documentsGradesAdapter.getInitialState(),
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return documentsGradesAdapter.getInitialState();
        });
    },
    reducers: {
        setAllDocumentsGradesAdapter: documentsGradesAdapter.setAll,
        addManyDocumentsGradesAdapter: documentsGradesAdapter.addMany,
        addOneDocumentsGradesAdapter: documentsGradesAdapter.addOne,
        removeOneDocumentsGradesAdapter: documentsGradesAdapter.removeOne,
        upsertOneDocumentsGradesAdapter: documentsGradesAdapter.upsertOne,
        upsertManyDocumentsGradesAdapter: documentsGradesAdapter.upsertMany,
        updateManyDocumentsGradesAdapter: documentsGradesAdapter.updateMany,
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    addManyDocumentsGradesAdapter,
    removeOneDocumentsGradesAdapter,
    upsertOneDocumentsGradesAdapter,
    addOneDocumentsGradesAdapter,
    upsertManyDocumentsGradesAdapter,
    setAllDocumentsGradesAdapter,
    updateManyDocumentsGradesAdapter,
} = documentsGradesSlice.actions;

const globalizedSelectors = documentsGradesAdapter.getSelectors(
    (state) => state.entities.documentsGrades
);

export const selectDocumentsPreinscriptions = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllDocumentsPreinscriptions = (state) =>
    globalizedSelectors.selectAll(state);

export default documentsGradesSlice.reducer;
